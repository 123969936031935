.table {
  :global {
    .ant-table-footer {
      padding-left: 0 !important;
      padding-bottom: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.modal {
  :global {
    .ant-modal-header {
      padding-bottom: 16px !important;
    }
  }
}

.settingsButton {
  display: flex !important;
  align-items: center !important;
}