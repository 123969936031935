@import '@/styles/colors.module';

.pagination {
  :global {
    .ant-pagination-item {
      border-radius: 50% !important;
      a {
        font-weight: 600 !important;
        color: $light_blue !important;
      }

      &-active {
        background-color: $gray !important;
        border: none !important;
        a {
          color: black !important;
        }
      }
    }
  }
}
